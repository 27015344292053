@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* colors */
    --color-palup-brand-yellow: #f1d148;
    --color-palup-black: #2e302e;
    --color-palup-white: #ffffff;
    --color-palup-blue: #83dad0;
    --color-palup-green: #b1e163;
    --color-palup-orange: #f19948;
    --color-floating: #ffffff;
    --color-alert: #f74a55;
    --color-overlay: rgba(0, 5, 5, 0.3);
    --color-overlay-light: rgba(0, 5, 5, 0.2);
    --color-overlay-still: rgba(0, 5, 5, 0.6);
    --color-overlay-thick: rgba(0, 5, 5, 0.85);

    /* colors L */
    --color-l-l1: #2e302e;
    --color-l-l1-keep: #2e302e;
    --color-l-l2: rgba(46, 48, 46, 0.6);
    --color-l-l3: rgba(46, 48, 46, 0.3);

    /* colors Button */
    --color-button-p1: #3c7b46;
    --color-button-p1-hover: #32713c;
    --color-button-p2: rgba(60, 123, 70, 0.15);
    --color-button-p2-hover: rgba(60, 123, 70, 0.25);
    --color-button-p3: rgba(60, 123, 70, 0.25);
    --color-button-p3-hover: rgba(60, 123, 70, 0.35);
    --color-button-on-primary: #ffffff;
    --color-button-on-primary-hover: #f9fff3;

    /* colors Gray */
    --color-gray-gray1: #2e302e;
    --color-gray-gray2: #545d58;
    --color-gray-gray3: #b4bdb5;
    --color-gray-gray4: #e0ede8;
    --color-gray-gray5: #fdfaff;

    /* colors Background */
    --color-background-b1: #f2f4f5;
    --color-background-1st: #ffffff;
    --color-background-2nd: #f2f4f5;
    --color-background-1st-30blur: #ffffff;
    --color-background-1st-50blur: #ffffff;

    /* colors Background Elevated */
    --color-background-elevated-b1: #f2f4f5;
    --color-background-elevated-1st: #ffffff;
    --color-background-elevated-2nd: #f2f4f5;

    /* colors Glass */
    --color-glass-alpha-0: rgba(177, 174, 170, 0);
    --color-glass-tips: rgba(195, 213, 220, 0.4);
    --color-glass-options: rgba(180, 194, 200, 0.15);
    --color-glass-separators: rgba(180, 194, 200, 0.2);
    --color-glass-ios-20blur: rgba(247, 249, 250, 0.75);
    --color-glass-android: rgba(255, 255, 255, 0.95);

    --color-label-l1: #2e302e;
    --color-label-l2: rgba(46, 48, 46, 0.6);
    --color-label-l3: rgba(46, 48, 46, 0.3);
    --color-primary-p1: #3c7b46;
    --color-primary-p2: rgba(60, 123, 70, 0.15);
    --color-primary-p2-hover: rgba(60, 123, 70, 0.25);
    --color-primary-on-primary: #f0ffe0;
    --color-secondary-p1: #32713c;
    --color-secondary-p2: rgba(60, 123, 70, 0.25);
    --color-secondary-on-secondary: #f9fff3;
    --color-third-p1: #f74a55;
    --color-third-p2: rgba(255, 120, 10, 0.08);
    --color-third-on-third: #fafdff;
    --color-playseeGray-gray1: #2e302e;
    --color-playseeGray-gray2: #545d58;
    --color-playseeGray-gray3: #93a7ae;
    --color-playseeGray-gray4: #e0ede8;
    --color-playseeGray-gray5: #fdfaff;
    --color-embed-payment-bg: #f1d148;

    --color-label-still-l1: #fafdff;
    --color-label-still-l2: rgba(243, 255, 249, 0.6);
    --color-label-still-l3: rgba(243, 255, 249, 0.3);
    --color-primary-still-p1: #3d8c49;
    --color-primary-still-p2: rgba(63, 160, 79, 0.15);
    --color-primary-still-on-primary: #f0ffe0;
    --color-secondary-still-p1: #429753;
    --color-secondary-still-p2: rgba(63, 160, 79, 0.25);
    --color-secondary-still-on-secondary: #f9fff3;
    --color-third-still-p1: #ff765c;
    --color-third-still-p2: rgba(255, 204, 163, 0.16);
    --color-third-still-on-third: #fafdff;
    --color-playseeGray-still-gray1: #fdfaff;
    --color-playseeGray-still-gray2: #e0ede8;
    --color-playseeGray-still-gray3: #b4bdb5;
    --color-playseeGray-still-gray4: #545d58;
    --color-playseeGray-still-gray5: #2e302e;
    --color-background-still-b1: #0c1212;
    --color-background-still-1st: #202525;
    --color-background-still-2nd: #353a3a;
    --color-background-still-elevated-b1: #202525;
    --color-background-still-elevated-1st: #353a3a;
    --color-background-still-elevated-2nd: #4b5050;
    --color-floating-still: #515656;
    --color-alert-still: #f74a55;

    --color-glass-special-alpha-0: ;
    --color-glass-special-tips: rgba(195, 213, 220, 0.4);
    --color-glass-special-options: rgba(180, 194, 200, 0.15);
    --color-glass-special-separators: rgba(180, 194, 200, 0.2);
    --color-glass-ios-20blur-light: rgba(247, 249, 250, 0.75);
    --color-glass-ios-20blur-still: rgba(69, 74, 74, 0.7);
    --color-glass-android-light: hsla(0, 0%, 100%, 0.95);
    --color-glass-android-still: rgba(69, 74, 74, 0.7);

    /* message color(web only) */
    --color-message-success: rgba(228, 245, 205, 1);
    --color-message-error: rgba(255, 193, 198, 1);
    --color-message-warning: rgba(253, 219, 195, 1);
    --color-message-info: rgba(210, 243, 239, 1);

    /* font size */
    --font-size-6xl: 60px;
    --font-size-3xl: 30px;
    --font-size-2xl: 20px;
    --font-size-xl: 18px;
    --font-size-lg: 16px;
    --font-size-md: 14px;
    --font-size-sm: 12px;
    --font-size-xs: 10px;
    --font-size-xl-title: 50px;
    --font-size-big-title: 30px;
    --font-size-mid-title: 24px;
    --font-size-title: 20px;
    --font-size-small-title: 19px;
    --font-size-header: 16px;
    --font-size-subtitle: 15px;
    --font-size-main-text: 20px;
    --font-size-large-body: 17px;
    --font-size-body: 15px;
    --font-size-callout: 16px;
    --font-size-caption: 13px;
    --font-size-mini: 12px;
    --font-size-quote: 13px;

    /* font weight */
    --font-weight-xl-title: 700;
    --font-weight-big-title: 700;
    --font-weight-mid-title: 700;
    --font-weight-title: 700;
    --font-weight-small-title: 700;
    --font-weight-header: 800;
    --font-weight-subtitle: 600;
    --font-weight-main-text: 400;
    --font-weight-large-body: 400;
    --font-weight-body: 400;
    --font-weight-callout: 700;
    --font-weight-caption: 400;
    --font-weight-mini: 500;
    --font-weight-quote: 600;

    /* line height */
    --font-size-6xl--line-height: 64px;
    --font-size-3xl--line-height: 36px;
    --font-size-2xl--line-height: 24px;
    --font-size-xl--line-height: 22px;
    --font-size-lg--line-height: 20px;
    --font-size-md--line-height: 18px;
    --font-size-sm--line-height: 16px;
    --font-size-xs--line-height: 12px;
    --line-height-xl-title: 60px;
    --line-height-big-title: 36px;
    --line-height-mid-title: 30px;
    --line-height-title: 24px;
    --line-height-small-title: 20px;
    --line-height-header: 20px;
    --line-height-subtitle: 18px;
    --line-height-main-text: 24px;
    --line-height-large-body: 20px;
    --line-height-body: 18px;
    --line-height-callout: 18px;
    --line-height-caption: 16px;
    --line-height-mini: 12px;
    --line-height-quote: 16px;

    /* letter spacing */
    --letter-spacing-xl-title: 0.01em;

    --shadow-floating: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
    --shadow-floating-button: 0px 0px 2px 0px rgba(0, 0, 0, 0.12),
      0px 4px 16px 0px rgba(0, 0, 0, 0.15);
    --shadow-floating-dropdown: 0px 0px 12px rgba(0, 0, 0, 0.07),
      0px 0px 4px rgba(0, 0, 0, 0.04);
    --shadow-toast: 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05);
    --shadow-card: 0px 0px 4px 0px rgba(0, 0, 0, 0.04),
      0px 0px 12px 0px rgba(0, 0, 0, 0.07);

    --drop-shadow-image: 0px 1px 3px rgba(0, 0, 0, 0.12),
      0px 0px 2px rgba(0, 0, 0, 0.12);

    --z-index-header: 20;
    --z-index-mobile-nav: 10;
    --z-index-modal: 20;
    --z-index-modal-1: calc(var(--z-index-modal) - 1);
    --z-index-alert: 25;
    --z-index-alert-1: calc(var(--z-index-alert) - 1);
    --z-index-popover: 30;
    --z-index-popover-1: calc(var(--z-index-popover) - 1);
    --z-index-download: 30;
    --z-index-toast: 9999;

    --spacing-fixed-top: 69px;
    --spacing-mobile-fixed-top: 48px;
    --spacing-consent-height: 0px;

    /* Other config */
    --ramp: rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 8%,
      rgba(0, 0, 0, 0.38) 16%, rgba(0, 0, 0, 0.36) 23.8%,
      rgba(0, 0, 0, 0.33) 31.6%, rgba(0, 0, 0, 0.3) 39.3%,
      rgba(0, 0, 0, 0.26) 46.7%, rgba(0, 0, 0, 0.22) 54%,
      rgba(0, 0, 0, 0.18) 61%, rgba(0, 0, 0, 0.14) 67.7%,
      rgba(0, 0, 0, 0.1) 74.1%, rgba(0, 0, 0, 0.07) 80.1%,
      rgba(0, 0, 0, 0.04) 85.8%, rgba(0, 0, 0, 0.02) 91%, rgba(0, 0, 0, 0) 95.7%,
      rgba(0, 0, 0, 0) 100%;

    --gradient-feed-picker: linear-gradient(
      180deg,
      #000000 0%,
      rgba(0, 0, 0, 0.99) 7.4%,
      rgba(0, 0, 0, 0.95) 15.7%,
      rgba(0, 0, 0, 0.9) 22%,
      rgba(0, 0, 0, 0.82) 29.5%,
      rgba(0, 0, 0, 0.74) 35.3%,
      rgba(0, 0, 0, 0.65) 41.1%,
      rgba(0, 0, 0, 0.55) 47.1%,
      rgba(0, 0, 0, 0.45) 52.9%,
      rgba(0, 0, 0, 0.35) 58.9%,
      rgba(0, 0, 0, 0.26) 64.7%,
      rgba(0, 0, 0, 0.18) 70.5%,
      rgba(0, 0, 0, 0.1) 78%,
      rgba(0, 0, 0, 0.05) 84.3%,
      rgba(0, 0, 0, 0.01) 92.6%,
      rgba(0, 0, 0, 0) 100%
    );
    --gradient-community-picker: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.99) 7.4%,
      rgba(255, 255, 255, 0.95) 15.7%,
      rgba(255, 255, 255, 0.9) 22%,
      rgba(255, 255, 255, 0.82) 29.5%,
      rgba(255, 255, 255, 0.74) 35.3%,
      rgba(255, 255, 255, 0.65) 41.1%,
      rgba(255, 255, 255, 0.55) 47.1%,
      rgba(255, 255, 255, 0.45) 52.9%,
      rgba(255, 255, 255, 0.35) 58.9%,
      rgba(255, 255, 255, 0.26) 64.7%,
      rgba(255, 255, 255, 0.18) 70.5%,
      rgba(255, 255, 255, 0.1) 78%,
      rgba(255, 255, 255, 0.05) 84.3%,
      rgba(255, 255, 255, 0.01) 92.6%,
      rgba(255, 255, 255, 0) 100%
    );
    --gradient-home-header: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.15) 8%,
      rgba(0, 0, 0, 0.13) 28.2%,
      rgba(0, 0, 0, 0.11) 39.3%,
      rgba(0, 0, 0, 0.08) 55.4%,
      rgba(0, 0, 0, 0.05) 67.7%,
      rgba(0, 0, 0, 0.01) 87.7%,
      rgba(0, 0, 0, 0) 100%
    );

    --gradient-suggested-avatar: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.395) 8%,
      rgba(0, 0, 0, 0.381) 16%,
      rgba(0, 0, 0, 0.358) 23.8%,
      rgba(0, 0, 0, 0.33) 31.6%,
      rgba(0, 0, 0, 0.296) 39.3%,
      rgba(0, 0, 0, 0.259) 46.7%,
      rgba(0, 0, 0, 0.22) 54%,
      rgba(0, 0, 0, 0.18) 61%,
      rgba(0, 0, 0, 0.141) 67.7%,
      rgba(0, 0, 0, 0.104) 74.1%,
      rgba(0, 0, 0, 0.07) 80.1%,
      rgba(0, 0, 0, 0.042) 85.8%,
      rgba(0, 0, 0, 0.019) 91%,
      rgba(0, 0, 0, 0.005) 95.7%,
      rgba(0, 0, 0, 0) 100%
    );

    /* for react-day-picker */
    /* Size of the day cells. */
    --rdp-cell-size: 40px;
    /* Font size for the caption labels. */
    --rdp-caption-font-size: var(--font-size-lg);
    /* Accent color for the background of selected days. */
    --rdp-accent-color: var(--color-primary-p1);
    /* Accent color for the background of selected days */
    --rdp-accent-color-dark: var(--color-primary-p1);
    /* Background color for the hovered/focused elements. */
    --rdp-background-color: var(--color-primary-p2);
    /* Background color for the hovered/focused elements */
    --rdp-background-color-dark: var(--color-primary-p2);
    /* Outline border for focused elements. */
    --rdp-outline: 2px solid var(--color-primary-p1);
    /* Outline border for focused and selected elements. */
    --rdp-outline-selected: 3px solid var(--color-primary-p1);
    /* Color of selected day text. */
    --rdp-selected-color: #fff;

    --content-size: 600px;
    --content-padding: 16px;
    --max-content-size: calc(
      var(--content-size) + (var(--content-padding) * 2)
    );
    --max-app-size: 1280px;

    --side-left-width: 80px;
    --side-right-width: 320px;

    --scroll-spacer-height: 12px;
    --scroll-padding-top: 24px;
    --scroll-padding-bottom: 88px;

    --bottom-nav-height: 0px;

    /* Template Card */
    --template-card-height: 242px;
    --template-card-image-radius: 18px;

    /* Dialog */
    --dialog-max-width: 600px;
    /* Main Content */
    --main-content-max-width: 1000px;

    /* Recommend Card */
    --recommend-post-card-single-width: 291px;
    --recommend-post-card-single-height: 407px;
    --recommend-post-card-multiple-width: 280px;
    --recommend-post-card-multiple-height: 392px;
    --recommend-post-video-single-card-height: 311px;
    --recommend-post-video-multiple-card-height: 296px;
    --recommend-post-content-height: 156px;

    /* Suggested Avatar */
    --suggested-avatar-width: 127px;
    --suggested-avatar-height: 190px;
    --suggested-avatar-content-height: 64px;

    @media (min-width: 768px) {
      --side-left-width: 200px;
    }

    @media (min-width: 1280px) {
      --side-left-width: 240px;
      --side-right-width: 360px;
      --scroll-padding-top: 24px;
      --scroll-padding-bottom: 36px;
    }
  }

  .dark {
    --color-palup-brand-yellow: #f1d148;
    --color-palup-black: #2e302e;
    --color-palup-white: #ffffff;
    --color-palup-blue: #83dad0;
    --color-palup-green: #b1e163;
    --color-palup-orange: #f19948;
    --color-floating: #515656;
    --color-alert: #f74a55;
    --color-overlay: rgba(0, 5, 5, 0.6);
    --color-overlay-light: rgba(0, 5, 5, 0.2);
    --color-overlay-thick: rgba(0, 5, 5, 0.85);

    /* colors L */
    --color-l-l1: #fafdff;
    --color-l-l1-keep: #fafdff;
    --color-l-l2: rgba(243, 255, 249, 0.6);
    --color-l-l3: rgba(243, 255, 249, 0.3);

    /* colors Button */
    --color-button-p1: #3d8c49;
    --color-button-p1-hover: #3c7b46;
    --color-button-p2: rgba(63, 160, 79, 0.15);
    --color-button-p2-hover: rgba(63, 160, 79, 0.25);
    --color-button-p3: rgba(61, 140, 73, 0.5);
    --color-button-p3-hover: rgba(66, 151, 83, 0.55);
    --color-button-on-primary: #f0ffe0;
    --color-button-on-primary-hover: #f9fff3;

    /* colors Gray */
    --color-gray-gray1: #fdfaff;
    --color-gray-gray2: #e0ede8;
    --color-gray-gray3: #b4bdb5;
    --color-gray-gray4: #545d58;
    --color-gray-gray5: #2e302e;

    /* colors Background */
    --color-background-b1: #0c1212;
    --color-background-1st: #202525;
    --color-background-2nd: #353a3a;
    --color-background-1st-30blur: rgba(32, 37, 37, 0.3);
    --color-background-1st-50blur: rgba(32, 37, 37, 0.5);

    /* colors Background Elevated */
    --color-background-elevated-b1: #202525;
    --color-background-elevated-1st: #353a3a;
    --color-background-elevated-2nd: #454a4a;

    /* colors Glass */
    --color-glass-alpha-0: rgba(177, 174, 170, 0);
    --color-glass-tips: rgba(125, 128, 129, 0.4);
    --color-glass-options: rgba(180, 194, 200, 0.15);
    --color-glass-separators: rgba(180, 194, 200, 0.2);
    --color-glass-ios-20blur: rgba(69, 74, 74, 0.7);
    --color-glass-android: rgba(69, 74, 74, 0.9);

    /* message color(web only) */
    --color-message-success: rgba(79, 101, 59, 1);
    --color-message-error: rgba(110, 44, 50, 1);
    --color-message-warning: rgba(107, 72, 48, 1);
    --color-message-info: rgba(59, 98, 94, 1);
  }

  html,
  body {
    width: 100%;
    height: 100%;
  }

  body {
    @apply text-md text-label-l1 relative bg-white;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  img,
  video {
    /* WebKit browsers (Chrome, Safari) */
    -webkit-touch-callout: none;
    -webkit-user-select: none;

    /* Mozilla Firefox */
    -moz-user-select: none;

    /* Internet Explorer/Edge */
    -ms-user-select: none;

    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    user-select: none;

    /* Disable dragging */
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }

  .playsee-mobile {
    --scroll-padding-bottom: 0;
    --scroll-spacer-height: 0;
    --scroll-padding-top: 0;

    --bottom-nav-height: 64px;
  }
}

@layer components {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .form-action-button {
    @apply bg-background-2nd text-label-l2 inline-block cursor-pointer rounded-[6px] px-[9px] py-[7px];

    &[data-selected],
    &.selected {
      @apply bg-primary-p2 text-primary-p1;
    }
  }
  .auto-height-textarea {
    min-block-size: 1rlh;
    field-sizing: content;
  }
  .linear-gradient-bg-x {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.38),
      rgba(0, 0, 0, 0.36),
      rgba(0, 0, 0, 0.33),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.26),
      rgba(0, 0, 0, 0.22),
      rgba(0, 0, 0, 0.18),
      rgba(0, 0, 0, 0.14),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  .linear-gradient-bg-y {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.38),
      rgba(0, 0, 0, 0.36),
      rgba(0, 0, 0, 0.33),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.26),
      rgba(0, 0, 0, 0.22),
      rgba(0, 0, 0, 0.18),
      rgba(0, 0, 0, 0.14),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.07),
      rgba(0, 0, 0, 0.04),
      rgba(0, 0, 0, 0.02),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
  }
}
